$(document).ready(() => {
  /**
   * Smooth scrolling to page anchor on click
   */
  $('#product .read-description.smoothscroll').click((e) => {
    e.preventDefault();
    const offset = $('#extra-0').offset().top - 170;
    if (!$('#extra-0').hasClass('active')) {
      $('#tab-0 .nav-link').trigger('click');
    }

    $('html, body').animate({
      scrollTop: offset,
    }, 800);
  });
});
