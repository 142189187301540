$(document).ready(() => {
  $('[data-action="scroll-to"]').on('click', function handleScrollClick(e) {
    e.preventDefault();
    const target = $(this).data('target');
    $('html, body').animate({
      scrollTop: $(target).offset().top,
    }, 500);
  });

  $('[data-bs-toggle="modal"]').on('click', () => {
    const target = $(this).data('bs-target');
    $(target).modal('show');
  });

  const maskedContentElement = document.querySelector('#welcome .masked-content');
  const originalContentElement = document.querySelector('#welcome .original');
  const cloneContentElement = document.querySelector('#welcome .clone');

  if (maskedContentElement && originalContentElement && cloneContentElement) {
    const imgDesktopElement = maskedContentElement.querySelector('.img-desktop');
    const imgMobileElement = maskedContentElement.querySelector('.img-mobile');

    if (imgDesktopElement) {
      imgDesktopElement.classList.add('d-lg-block');
    }
    if (imgMobileElement) {
      imgMobileElement.classList.add('d-block', 'd-lg-none');
    }

    maskedContentElement.classList.add('ini-mask');

    const originalTitleElement = originalContentElement.querySelector('.title');
    const originalTextElement = originalContentElement.querySelector('.text');
    const cloneTitleElement = cloneContentElement.querySelector('.title');
    const cloneTextElement = cloneContentElement.querySelector('.text');

    if (originalTitleElement && cloneTitleElement) {
      cloneTitleElement.innerHTML = originalTitleElement.innerHTML;
    }
    if (originalTextElement && cloneTextElement) {
      cloneTextElement.innerHTML = originalTextElement.innerHTML;
    }
  }
});
